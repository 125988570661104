<template>
  <v-navigation-drawer v-model="sidebarDrawer" :dark="SidebarColor == $vuetify.theme.dark" :color="SidebarColor" app
    clipped mini-variant-width="60" :mini-variant="expandOnHover" :expand-on-hover="expandOnHover"
    mobile-breakpoint="960" :width="260">
    <v-list nav dense>
      <v-list-item two-line class="px-0 user-info" @click="userInfoActive = true">
        <v-list-item-avatar>
          <img :alt="currentUser ? currentUser.nama : 'User'" :src="currentUser ? currentUser.avatar_path : ''" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title v-on="on" v-bind="attrs">{{
                currentUser ? currentUser.nama : "User"
              }}</v-list-item-title>
            </template>
            <span>{{ currentUser ? currentUser.nama : "User" }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-subtitle v-on="on" v-bind="attrs" class="caption">{{
                currentUser ? currentUser.nama_jabatan : ""
              }}</v-list-item-subtitle>
            </template>
            <span>{{ currentUser ? currentUser.nama_jabatan + ' - ' + currentUser.nama_unit : "" }}</span>
          </v-tooltip>
        </v-list-item-content>
      </v-list-item>

      <div class="d-flex align-content-start flex-wrap" style="width: 100%;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: flex-start;justify-content: flex-start;align-items: center;" v-if="false">
        <v-btn tile class="px-4 app-drawer-button primary mb-2 not-elevated" style="width:75%;" @click="createGeneralMemo">{{ $t("button.write-general-memo") }}
        </v-btn>
        <v-menu offset-y left style="width: auto;">
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile color="orange" class="px-4 dropdown-button not-elevated" style="margin-top:-8px;" dark v-bind="attrs" v-on="on">
               <v-icon small>{{ iconDots }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in createMenus" :key="index">
              <v-list-item-title @click="runAction(item.action)">{{ $t('menu.'+item.title) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
       
      </div>
      <div class="d-flex align-content-start flex-wrap" style="width: 100%;display: flex;flex-direction: row;flex-wrap: nowrap;align-content: flex-start;justify-content: flex-start;align-items: center;" v-if="this.currentUser.usertype_elemen ==='SEKRETARIS_DIRSEVP'">
        <v-btn tile class="px-4 primary mb-2 not-elevated w-full" style="width: 100%;" @click="runAction('create_incoming_letter')">{{ $t("button.write-incoming-letter") }}
        </v-btn>
      </div>

      <sidebar-item v-bind:unreadCount="unreadCount" class="mb-5 mt-4" :links="myMenu.memoMenu"></sidebar-item>
      <sidebar-item v-bind:unreadCount="unreadCount" :links="myMenu.taskMenu"></sidebar-item>
      <hr v-if="userIsAdmin || userIsSuperAdmin" class="mt-4 menu--divider" />
      <span v-if="userIsAdmin || userIsSuperAdmin" class="app-menu--subtitle px-4 pb-2 pt-4">Admin</span>
      <sidebar-item v-if="userIsAdmin" :links="adminLinks"></sidebar-item>
      <sidebar-item v-if="userIsSuperAdmin" :links="superAdminLinks"></sidebar-item>
      <sidebar-item v-if="userIsAdminLembaga" :links="lembagaAdminLink"></sidebar-item>
    </v-list>
    <userAuthInfoModal :userInfoActive="userInfoActive" @closeUserInfo="userInfoActive = false"></userAuthInfoModal>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { mdiContentSave,mdiDotsHorizontal } from "@mdi/js";
import { vapidPublicKey } from "@/constants/config";
import appMenu from "@/constants/appmenu";
import SidebarItem from "./SidebarItem.vue";
import { http } from "@/http/http";
import userAuthInfoModal from "@/components/commonComponents/UserAuthInfoModal.vue";

export default {
  components: { SidebarItem, userAuthInfoModal },
  name: "Sidebar",
  data: () => ({
    // memoLinks: appMenu.appSidebarMenu.memoMenu,
    // taskLinks: appMenu.appSidebarMenu.taskMenu,
    adminLinks: appMenu.appSidebarMenu.menuAdmin,
    superAdminLinks: appMenu.appSidebarMenu.menuSuperAdmin,
    reportLinks: appMenu.appSidebarMenu.menuReport,
    lembagaAdminLink: appMenu.appSidebarMenu.menuMasterLembaga,
    iconSave: mdiContentSave,
    iconDots: mdiDotsHorizontal,
    loading: false,
    isPushEnabled: false,
    pushButtonDisabled: true,
    userInfoActive: false,
    isPjpSekper: false,
    isIncomingSekper: false,
  }),
  created(){
   http
        .get("/src/pjp-sekper")
        .catch((error) => console.error(error))
        .then((response) => {
            if (response && response.data === 'yes-you-are-pjp-sekper') this.isPjpSekper = true;
        });
   http
        .get("/src/incoming-sekper")
        .catch((error) => console.error(error))
        .then((response) => {
            if (response && response.data === 'yes-you-are-incoming-operator-sekper') this.isIncomingSekper = true;
        });
  },
  mounted() {
    // this.registerServiceWorker();
    this.getDataResource({ source: "counting", tipe:"all" });
  },
  computed: {
    ...mapState(["SidebarColor", "SidebarBg", "isMobile", "expandOnHover"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("resources", ["myUnreadCount"]),
    createMenus() {
      let unitHasBagian = ['00','10'];
        if (this.currentUser && unitHasBagian.includes(this.currentUser.kode_unit) && !this.isIncomingSekper) {
        return [
          { title: 'permit-memo', action:'create_permit_memo' },
          { title: 'external-letter', action:'create_external_letter' },
        ];
        } else if (this.currentUser && unitHasBagian.includes(this.currentUser.kode_unit) && this.isIncomingSekper){
          return [
          { title: 'permit-memo', action:'create_permit_memo' },
          { title: 'incoming-letter', action:'create_incoming_letter' },
          { title: 'external-letter', action:'create_external_letter' },
        ];
        } else {
        return [
          { title: 'external-letter', action:'create_external_letter' },
        ];
        }
    },
    unreadCount() {
      if (!this.myUnreadCount) return undefined;
      return this.myUnreadCount.counting;
    },
    userType() {
      if (!this.currentUser) return null;
      return this.currentUser.usertype_elemen;
    },
    userIsAdmin() {
      if (!this.currentUser) return false;
      return this.currentUser.admin == true;
    },
    userIsSekretaris() {
      if (!this.currentUser) return false;
      return this.currentUser.is_secretary == true && this.currentUser.usertype_elemen !== 'SEKRETARIS_KRANI';
    },
    userIsSuperAdmin() {
      if (!this.currentUser) return false;
      return this.currentUser.superadmin == true;
    },
    userIsDir() {
      if (!this.currentUser) return false;
      return ["DIREKTUR"].includes(this.currentUser.usertype_elemen);
    },
    userIsPjp() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS"].includes(this.currentUser.usertype_elemen);
    },
    userIsDirPjp() {
      if (!this.currentUser) return false;
      return ["DIREKTUR", "PJP", "PJP_KANPUS"].includes(
        this.currentUser.usertype_elemen
      );
    },
    userIsAdminLembaga() {
      if (!this.currentUser) return false;
      return this.isIncomingSekper;
      // return ["4000168", "4000167", "4022966", "4000607","3025100","3025100A","3024410","3024410A","3023271","3023271A","3024428","3025106","3023264","3023317"].includes(this.currentUser.nik_sap);
    },
    userSekper() {
      if (!this.currentUser) return false;
      return (
        this.currentUser.kode_unit === "00" &&
        this.currentUser.kode_bagian == "BAG237"
      );
    },
    pjpSekper(){
      if (!this.currentUser) return false;
      return this.isPjpSekper;
    },
    userHQDistrik() {
      if (!this.currentUser) return false;
      return ["00", "01", "02", "03", "04"].includes(
        this.currentUser.kode_unit
      );
    },
    myMenu() {
      if (!this.userType) return [];
      let jabatanDirut = ['DIREKTUR','DIREKTUR UTAMA'];
      let menu = [];
      switch (this.userType.toLowerCase()) {
        case "direktur":
          menu = appMenu.appSidebarMenu.menuSevp;
          break;
        case "pjp":
        case "pjp_kanpus":
          if (this.pjpSekper) {
            menu = appMenu.appSidebarMenu.menuPjpSekper;
          }
          else {
            menu = appMenu.appSidebarMenu.menuPjp;
          }
          break;
        case "kasubag":
        case "kadis":
        case "karpim":
        case "karpim_kanpus":
          menu = appMenu.appSidebarMenu.menuKarpim;
          break;
        case "sekretaris":
          menu = appMenu.appSidebarMenu.menuSekretaris;
          break;
        case "sekretaris_dirsevp":
        case "sekretaris_direksi":
          menu = appMenu.appSidebarMenu.menuSekretarisDirSevp;
          break;
        default:
          menu = appMenu.appSidebarMenu.menuKrani;
          break;
      }
      if (jabatanDirut.includes(this.currentUser.nama_jabatan)) menu = appMenu.appSidebarMenu.menuDir;

      return menu;
    },
    sidebarDrawer: {
      get() {
        return this.$store.state.sidebarDrawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
  // watch: {
  //   "$vuetify.breakpoint.smAndDown"(val) {
  //     this.$emit("update:expandOnHover", !val);
  //   },
  // },

  methods: {
    ...mapActions(["setCreateGeneralMemo", "setCreatePermitMemo", "setCreateIncomingLetter","setCreateXLetter"]),
    ...mapActions("draft", [
      "setEditGeneralMemo",
      "setEditPermitMemo",
      "setMemoToReply",
    ]),
    ...mapActions("resources", ["getDataResource"]),
    runAction(actionName) {
      switch (actionName) {
        case 'create_permit_memo':
          this.createPermitMemo();
          break;
          case 'create_incoming_letter':
            this.setCreateIncomingLetter(true);
            break;
          case 'create_external_letter':
            this.setCreateXLetter(true);
            break;
        default:
          break;
      }
    },
    createGeneralMemo() {
      this.setMemoToReply(undefined);
      this.setEditGeneralMemo({
        show: false,
        editParams: {
          memo_id: null,
        },
      });
      this.setCreateGeneralMemo(true);
    },
    createPermitMemo() {
      this.setEditPermitMemo({
        show: false,
        editParams: {
          memo_id: null,
        },
      });
      this.setCreatePermitMemo(true);
    },
    clickMe() {
      console.log("clicked");
    },
    goTo(param) {
      this.$router.push(param);
    },
    togglePush() {
      if (this.isPushEnabled) {
        this.unsubscribe();
      } else {
        this.subscribe();
      }
    },
    subscribe() {
      var that = this;
      navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        let uint8Bit = that.urlBase64ToUint8Array(vapidPublicKey);
        let options = {
          userVisibleOnly: true,
          applicationServerKey: uint8Bit,
        };

        serviceWorkerRegistration.pushManager
          .subscribe(options)
          .then(function (subscription) {
            // The subscription was successful
            console.info(subscription);
            that.isPushEnabled = true;
          })
          .catch(function (e) {
            if (Notification.permission === "denied") {
              console.warn("Permission for Notifications was denied");
              that.isPushEnabled = false;
            } else {
              // A problem occurred with the subscription; common reasons
              // include network errors, and lacking gcm_sender_id and/or
              // gcm_user_visible_only in the manifest.
              console.error("Unable to subscribe to push.", e);
              that.isPushEnabled = false;
            }
          });
      });

      // navigator.serviceWorker.ready.then((registration) => {

      //   console.log('starting to subscribe');

      //   /*
      //   VAPID_PUBLIC_KEY=BOuC6wvgCUK8iOewErDYdqNYA_i-hqxjACfjBVjSMXHgoftd4P0c0BwYR9mIl3DmLA3APw6YMkG2CkIjl-lhPb0
      //   VAPID_PRIVATE_KEY=tO9eiyO1QbrfkmGWHfV0LLF--zalAEntWs5p51rbsnU
      //   */

      //   if (vapidPublicKey) {
      //     options.applicationServerKey = this.urlBase64ToUint8Array(vapidPublicKey);
      //   }
      //   console.log(options);

      //   registration.pushManager.subscribe(options)
      //     .then((subscription) => {
      //       console.log('yes subscribe');
      //       this.isPushEnabled = true;
      //       this.pushButtonDisabled = false;
      //       this.updateSubscription(subscription);
      //     })
      //     .catch((e) => {
      //       console.log(e)
      //       if (Notification.permission === "denied") {
      //         console.log("Permission for Notifications was denied");
      //         this.pushButtonDisabled = true;
      //       } else {
      //         console.log("Unable to subscribe to push.", e);
      //         this.pushButtonDisabled = false;
      //       }
      //     });
      // });
    },
    subscribeToServer() {
      navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        serviceWorkerRegistration.pushManager
          .subscribe()
          .then(function (subscription) {
            if (subscription) {
              this.isPushEnabled = true;
            }
            // The subscription was successful
          })
          .catch(function (e) {
            if (Notification.permission === "denied") {
              console.warn("Permission for Notifications was denied");
              this.isPushEnabled = false;
            } else {
              // A problem occurred with the subscription; common reasons
              // include network errors, and lacking gcm_sender_id and/or
              // gcm_user_visible_only in the manifest.
              console.error("Unable to subscribe to push.", e);
              this.isPushEnabled = false;
            }
          });
      });
    },
    unsubscribe() {
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager
          .getSubscription()
          .then((subscription) => {
            if (!subscription) {
              this.isPushEnabled = false;
              this.pushButtonDisabled = false;
              return;
            }
            subscription
              .unsubscribe()
              .then(() => {
                this.deleteSubscription(subscription);
                this.isPushEnabled = false;
                this.pushButtonDisabled = false;
              })
              .catch((e) => {
                console.error("Unsubscription error: ", e);
                this.pushButtonDisabled = false;
              });
          })
          .catch((e) => {
            console.error("Error thrown while unsubscribing.", e);
          });
      });
    },
    initialiseServiceWorker() {
      if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
        console.warn("Notifications aren't supported.");
        return;
      }
      if (Notification.permission === "denied") {
        console.warn("The user has blocked notifications.");
        return;
      }
      if (!("PushManager" in window)) {
        console.warn("Push messaging isn't supported.");
        return;
      }
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager
          .getSubscription()
          .then((subscription) => {
            this.pushButtonDisabled = false;
            if (!subscription) {
              return;
            }
            this.updateSubscription(subscription);
            this.isPushEnabled = true;
          })
          .catch((e) => {
            console.error("Error during getSubscription()", e);
          });
      });
    },
    registerServiceWorker() {
      if (!("serviceWorker" in navigator)) {
        console.warn("Service workers aren't supported in this browser.");
        return;
      }
      navigator.serviceWorker
        .register("/service-worker.js")
        .then(() => this.initialiseServiceWorker());
    },
    updateSubscription(subscription) {
      const key = subscription.getKey("p256dh");
      const token = subscription.getKey("auth");
      const contentEncoding = (PushManager.supportedContentEncodings || [
        "aesgcm",
      ])[0];
      const data = {
        endpoint: subscription.endpoint,
        publicKey: key
          ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
          : null,
        authToken: token
          ? btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
          : null,
        contentEncoding,
      };
      this.loading = true;
      http.post("/subscriptions", data).then(() => {
        this.loading = false;
      });
    },
    /**
     * Send a requst to the server to delete user's subscription.
     *
     * @param {PushSubscription} subscription
     */
    deleteSubscription(subscription) {
      this.loading = true;
      http
        .post("/subscriptions/delete", { endpoint: subscription.endpoint })
        .then(() => {
          this.loading = false;
        });
    },
    /**
     * Send a request to the server for a push notification.
     */
    sendNotification() {
      this.loading = true;
      http
        .post("/notifications")
        .catch((error) => console.error(error))
        .then(() => {
          this.loading = false;
        });
    },
    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
  },
};
</script>
<style>
.dropdown-button {
  min-width: 0px !important;
}
.v-list-item:hover {
  background-color: #eee;
}
.not-elevated {
  box-shadow: none !important;
}
</style>